<template>
  <div class="tagContainer">
    <h3>Etiquetas</h3>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <span
          v-for="item in tagUpdate"
          :style="`background:${item.color};color:${item.textColor}`"
        >
          {{ item.name }}
        </span>
        <button
          class="btnAddTag"
          @click="openTags('block')"
          v-if="
            get_tenant.isDispatcher &&
              $route.params.id == 'solicitacao' &&
              tags.tagStatus > 1 &&
              tagUpdate.some(x => x.id != 9)
          "
        >
          <img src="@/assets/image/icones/Adicionar.png" alt="Adicionar" />
        </button>
      </div>
      <div :class="get_tenant.isDispatcher ? 'd-flex' : 'visibled'">
        <div class="date">
          <img
            src="@/assets/image/icones/dateSucess.png"
            alt="dateSucess"
            @click="openDateTime(tags.serviceType)"
          />
          <datetime
            v-if="tags.serviceType === 7"
            type="datetime"
            ref="timeDate"
            value-zone="America/Sao_Paulo"
            :format="{
              year: 'numeric',
              day: 'numeric',
              month: 'numeric',
              hour: 'numeric',
              minute: '2-digit'
            }"
            :input-style="
              datetimeTheming
                ? 'border: none;   text-align: center; color: #f38235; font-weight: 600;'
                : 'width: 0px; opacity: 0; '
            "
            class="theme-orange showDisplay"
            v-model="datetimeTheming"
          >
          </datetime>
        </div>
        <button
          v-if="datetimeTheming"
          :disabled="load"
          class="btn sucess"
          @click="saveDate"
        >
          <p v-if="!load">Agendar</p>
          <half-circle-spinner
            v-else
            :animation-duration="1000"
            :size="30"
            color="#fff"
            class="mx-auto"
          />
        </button>
      </div>
    </div>
    <containerTagsVue
      :display="openDisplay"
      @openTags="openTags"
      :tagStatus.sync="tags.tagStatus"
      @updateStatus="updateStatus"
      :typeService="tags.serviceType"
    />
  </div>
</template>

<script>
import containerTagsVue from "./containerTags.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { HalfCircleSpinner } from "epic-spinners";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

export default {
  props: {
    tags: {
      type: [Array, Object],
      default: []
    }
  },
  components: {
    containerTagsVue,
    Datetime,
    HalfCircleSpinner
  },
  data() {
    return {
      openDisplay: "none",
      filterTags: [],
      filterTagsAll: [],
      changeTag: [],
      datetimeTheming: "",
      load: false,
      success: false
    };
  },
  computed: {
    ...mapGetters([
      "getTagsAll",
      "get_tenant",
      "getItemCardService",
      "getTenantIdCurrentDelete"
    ]),

    tagUpdate() {
      if (this.filterTags.length > 0) {
        return this.filterTags;
      }

      const result = this.filterTagsAll.filter(
        item => item.id === this.tags.tagStatus
      );
      return result;
    }
  },

  mounted() {
    this.filterTags = [];
    this.filterTagsAll = this.getTagsAll;
  },

  methods: {
    ...mapMutations(["setTagCurrent", "setOpenButtonSave"]),
    ...mapActions(["sendScheduling"]),

    async saveDate() {
      this.load = true;
      const jsonParams = {
        serviceDispatcherId: this.getItemCardService.id,
        dateTimeScheduling: this.datetimeTheming,
        tenantId: this.getTenantIdCurrentDelete
      };
      try {
        const result = await this.sendScheduling(jsonParams);
        this.toast("Agendado com sucesso", this.$toast.success);
        this.success = true;
        this.datetimeTheming = "";
        this.closeModal();
        this.load = false;
        document.location.reload(true);
      } catch (err) {
        this.toast(err.response.data.error.message, this.$toast.error);
        // this.toast("Erro", this.$toast.error);
        console.log(err);
        this.load = false;
      }
    },

    closeModal() {
      $("#modal_info").modal("hide");
    },

    openDateTime(type) {
      if (type == 7) {
        this.$refs.timeDate.isOpen = true;
        return;
      }
      this.toast(
        "Somente serviços com documentos pode ser agendado",
        this.$toast
      );
    },

    openTags(value) {
      this.openDisplay = value;
    },

    updateStatus(id) {
      this.openDisplay = "none";
      this.setTagCurrent(id);
      this.setOpenButtonSave(true);
      // if(this.tagUpdate[0].id !== id) {
      // }

      const result = this.getTagsAll.filter(item => item.id === id);
      this.filterTags = result;
    },

    clearFilter() {
      this.filterTags = [];
    }
  }
};
</script>

<style scoped>
.visibled {
  visibility: collapse;
}

.tagContainer {
  margin-top: 30px;
  position: relative;
}

.tagContainer h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4c4d5b;
}

.tag {
  background: #f65757;
  border-radius: 20px;
  font-size: 12px;
  height: 11px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.date {
  display: flex;
  cursor: pointer;
  background: #f9f9f9;
  border-radius: 5px;
  margin: 0 10px;
  align-items: center;
}

.date p {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #a5a6ad;
  margin: 0 5px 0 0;
}

.btnAddTag {
  margin: 0 0 0 10px;
  padding: 1px;
  height: 40px;
  border: none;
  border-radius: 5px;
  transition: 0.1s ease-in-out;
  background: none;
  outline: none;
}

.btnAddTag:hover {
  box-shadow: #dbd4d46b 0px 0px 5px 3px;
  color: #373841;
}

span {
  border-radius: 6px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  padding: 10px 20px;
  margin: 10px 2px;
  cursor: pointer;
  box-shadow: 0 8px 16px -4px #091e4240, 0 0 0 1px #091e4214;
  transition: 0.1s ease-in-out;
}

.btn {
  color: #ffffff !important;
  width: 110px !important;
}

.btn p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.sucess {
  background-color: #25d366;
}
</style>

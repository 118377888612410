<template>
  <Modal
    :modalId="`modal_pedido`"
    modalTitle="Acompanhamento do Pedido"
    :load="getdetailsServiceDespachante.load"
    :typeLg="'modal_lg'"
    @closeModal="closeModal"
  >
    <div class="header">
      <h2>#{{ item.orderId }} - {{ name }}</h2>
    </div>
    <div class="container p-0 mt-2">
      <half-circle-spinner
        :animation-duration="1000"
        :size="100"
        color="#f38235"
        class="py-5 mx-auto"
        v-if="cards.length <= 0"
      />
      <Draggables
        @openModal="openModal"
        :cards="cards"
        :idItem="item.id"
        v-else
      />
    </div>
    <Buttons @closeModal="closeModal" />
  </Modal>
</template>

<script>
import Modal from "../../modal/modalDefault.vue";
import Draggables from "./draggableComponente.vue";
import Buttons from "./acoes/botoes_rodape.vue";
import { HalfCircleSpinner } from "epic-spinners";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    item: {
      type: Object,
      default: {}
    },
    itemsAll: {
      type: Object,
      default: {}
    }
  },
  components: {
    Modal,
    Buttons,
    Draggables,
    HalfCircleSpinner
  },
  data() {
    return {
      cards: [],
      load: false
    };
  },
  computed: mapGetters([
    "getAccompanyServiceDespachante",
    "getdetailsServiceDespachante"
  ]),
  methods: {
    ...mapActions(["getServiceStatus"]),
    ...mapMutations(["setStatusAll"]),

    closeModal() {
      $("#modal_pedido").modal("hide");
    },

    openModal(...item) {
      const filter = item[0].filter(item => item.id);
      $("#modal_pedido").modal("hide");
      setTimeout(() => {
        $("#modal_info").modal("show");
      }, 100);
    },

    statusRequest() {
      this.getServiceStatus()
        .then(({ data }) => {
          const { result } = data;
          this.cards = result;
          this.setStatusAll(result);
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  watch: {
    item(newValue, oldValue) {
      this.statusRequest();
    }
  }
};
</script>

<style scoped>
.header {
  border-bottom: 1px solid #e9e9eb;
}

.header h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #797984;
  margin: 25px 0 0;
}

.container {
  height: 420px;
  background: #f8f8f9;
  border-radius: 15px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tagContainer"},[_c('h3',[_vm._v("Etiquetas")]),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._l((_vm.tagUpdate),function(item){return _c('span',{style:(("background:" + (item.color) + ";color:" + (item.textColor)))},[_vm._v(" "+_vm._s(item.name)+" ")])}),(
          _vm.get_tenant.isDispatcher &&
            _vm.$route.params.id == 'solicitacao' &&
            _vm.tags.tagStatus > 1 &&
            _vm.tagUpdate.some(function (x) { return x.id != 9; })
        )?_c('button',{staticClass:"btnAddTag",on:{"click":function($event){return _vm.openTags('block')}}},[_c('img',{attrs:{"src":require("@/assets/image/icones/Adicionar.png"),"alt":"Adicionar"}})]):_vm._e()],2),_c('div',{class:_vm.get_tenant.isDispatcher ? 'd-flex' : 'visibled'},[_c('div',{staticClass:"date"},[_c('img',{attrs:{"src":require("@/assets/image/icones/dateSucess.png"),"alt":"dateSucess"},on:{"click":function($event){return _vm.openDateTime(_vm.tags.serviceType)}}}),(_vm.tags.serviceType === 7)?_c('datetime',{ref:"timeDate",staticClass:"theme-orange showDisplay",attrs:{"type":"datetime","value-zone":"America/Sao_Paulo","format":{
            year: 'numeric',
            day: 'numeric',
            month: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
          },"input-style":_vm.datetimeTheming
              ? 'border: none;   text-align: center; color: #f38235; font-weight: 600;'
              : 'width: 0px; opacity: 0; '},model:{value:(_vm.datetimeTheming),callback:function ($$v) {_vm.datetimeTheming=$$v},expression:"datetimeTheming"}}):_vm._e()],1),(_vm.datetimeTheming)?_c('button',{staticClass:"btn sucess",attrs:{"disabled":_vm.load},on:{"click":_vm.saveDate}},[(!_vm.load)?_c('p',[_vm._v("Agendar")]):_c('half-circle-spinner',{staticClass:"mx-auto",attrs:{"animation-duration":1000,"size":30,"color":"#fff"}})],1):_vm._e()])]),_c('containerTagsVue',{attrs:{"display":_vm.openDisplay,"tagStatus":_vm.tags.tagStatus,"typeService":_vm.tags.serviceType},on:{"openTags":_vm.openTags,"update:tagStatus":function($event){return _vm.$set(_vm.tags, "tagStatus", $event)},"update:tag-status":function($event){return _vm.$set(_vm.tags, "tagStatus", $event)},"updateStatus":_vm.updateStatus}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
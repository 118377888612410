<template>
<div>
  <div class="container">
    <div class="row displayDesktop">
      <div class="col-1">
        <h4>PEDIDO</h4>
      </div>
      <div class="col-2 ">
        <h4>UNIDADE</h4>
      </div>
      <div class="col-2">
        <h4>PLACA</h4>
      </div>
      <div class="col-2">
        <h4>SERVIÇO</h4>
      </div>
      <div class="col-2">
        <h4>TAG</h4>
      </div>
      <div class="col-3">
        <h4>DATA/HORA</h4>
      </div>
    </div>
    <div v-if="!items.resultService || !items.resultService.length">
      <h2 class="text-center notFoundText">Nenhuma Solicitação Encontrada</h2>
    </div>
    <div v-else>
      <div class="d-flex flex-column flex-md-row containers my-4 my-md-2" v-for="(item, index) in items.resultService" :key="`${item.orderId}_${index}`">
        <div class="col-md-1 d-flex align-items-center justify-content-md-center justify-content-between m-md-auto rightBorder my-2">
          <h4 class="displayMobile">PEDIDO:</h4>
          <h4>#{{ item.orderId }}</h4>
        </div>
        <div class="col-md-2 unid d-flex align-items-center justify-content-md-center justify-content-between m-md-auto my-2 rightBorder">
          <h4 class="displayMobile">UNIDADE:</h4>
          <h4>{{ item.tenantName }}</h4>
        </div>
        <div class="col-md-2 unid d-flex align-items-center justify-content-md-center justify-content-between m-md-auto my-2 rightBorder">
          <h4 class="displayMobile">PLACA:</h4>
          <h4>
            {{
                item.vehiclePlate ? item.vehiclePlate : "Placa não informada"
              }}
          </h4>
        </div>
        <div class="col-md-2 d-flex align-items-center justify-content-md-center justify-content-between m-md-auto my-2 rightBorder">
          <h4 class="displayMobile">SERVIÇO:</h4>
          <h4>{{ item.serviceName }}</h4>
        </div>
        <div class="col-md-2 d-flex align-items-center justify-content-md-center justify-content-between m-md-auto my-2 rightBorder">
          <h4 class="displayMobile">TAG:</h4> <span :style="
                `background:${filterTags(item.tagStatus)[0].color};color:${
                  filterTags(item.tagStatus)[0].textColor
                }`
              ">
            {{ filterTags(item.tagStatus)[0].name }}
          </span>
        </div>
        <div class="col d-flex justify-content-md-center justify-content-between d-flex align-items-center m-md-auto my-2 rightBorder itens">
          <h4 class="displayMobile ">D/H:</h4>
          <h4 class="col p-0">{{ item.dateOperation.split(" ")[0] }}</h4>
          <h4 class="mx-1 p-0 col-3">
            {{ item.dateOperation.split(" ")[1] }}
          </h4>
          <button class="max col-2 p-0 displayDesktop" @click="$emit('openModal', 'modal_info', item, false)">
            <img src="@/assets/image/icones/arrows-maximize.png" alt="arrows" />
          </button>
          <div class="col-1 d-flex align-items-center justify-content-center m-auto p-0 notificationContainer">
            <svg v-show="item.notification" xmlns="http://www.w3.org/2000/svg" fill="#ffbc02" viewBox="0 0 448 512">
              <path d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z" />
            </svg>
          </div>
        </div>
        <div class="col my-2 displayMobile">
          <button class="btnMax p-0 " @click="$emit('openModal', 'modal_info', item, false)">Expandir <font-awesome-icon class="mx-3" icon="fas-light fa-maximize" /></button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapGetters
} from "vuex";
export default {
  props: {
    items: {
      type: Object,
      default: {},
      require: true
    }
  },
  data() {
    return {
      data: []
    };
  },
  computed: mapGetters(["getTagsAll"]),
  methods: {
    status(type) {
      const status = {
        0: "Pedido em Aberto",
        1: "Pendente",
        2: "Em Progresso",
        3: "Concluido",
        default: "Sem status"
      };
      return status[type] || status.default;
    },
    filterTags(tagStatus) {
      const result = this.getTagsAll.filter(item => item.id == tagStatus);
      return result;
    }
  }
};
</script>

<style scoped>
.container h4 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  margin: 0;
  text-align: center;
}

.containers:first-child {
  margin-top: 20px;
}

.containers {
  margin-top: 10px;
  background: #3f88c1;
  border-radius: 8px;
  min-height: 56px;
}

.containers h4 {
  font-weight: 600;
  font-size: 15px;
}

.itens h4 {
  font-weight: 600;
  font-size: 14px;
}

span {
  background: #ffbc02;
  border-radius: 18px;
  color: #fff;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  padding: 10px 20px;
}

.green {
  background: #25d366;
  height: 36px;
  width: 98%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue {
  height: 36px;
  width: 98%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5dadec;
}

.max {
  background: none;
  border: none;
  outline: none;
}

.unid {
  margin: 0;
}

.unid h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.notFoundText {
  font-weight: 600;
  margin-top: 100px;
  font-size: 16px;
  color: #98c4e6;
}

.notification {
  height: 9px !important;
  width: 9px !important;
  border-radius: 50%;
  background-color: #f38235;
  padding-left: 5px;
}

.notificationContainer {
  margin: 10px !important;
}

.displayDesktop {
  display: none;
}

.btnMax {
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 12px 10px !important;
  background: #f38235;
  color: #fff;
}

@media (min-width: 768px) {
  .displayMobile {
    display: none;
  }

  .displayDesktop {
    display: flex;
  }

  .rightBorder {
    height: 80%;
    border-right: 2px solid #f5f9fd26;
  }
}
</style>

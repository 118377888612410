<template>
<div class="container md-10 mb-5">
  <div>
    <header class="mt-5">
      <div class="welcomeMobile" v-if="this.sizeWindow <= 767">
        <router-link to="/" class="imgWelCont">
          <img src="../../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
        </router-link>
        <div class="txtWelCont">
          <p>Despachante</p>
        </div>
      </div>
      <ul v-if="this.sizeWindow > 767" class="d-flex align-content-lg-between align-items-center mb-2">
        <li class="mr-1">Home</li>
        <li class="mr-1">/ Despachante</li>
        <li class="text-white">
          /
          {{
              $route.params.id === "relatorios"
                ? "relatórios"
                : getAccompanyServiceDespachante.result.serviceName
            }}
        </li>
      </ul>
    </header>
  </div>

  <div v-if="$route.params.id === 'relatorios'" class="allContRel">
    <relatoriosVue />
  </div>
  <!-- <router-view ></router-view> -->
  <containerAcom v-else />
  <div class="my-4 d-flex justify-content-end" v-if="$route.params.id !== 'relatorios'">
    <vue-ads-pagination :total-items="numberPages" :max-visible-pages="4" :page="page" @page-change="pageChange">
      <template slot-scope="props">
        <div class="vue-ads-pr-2 vue-ads-leading-loose"></div>
      </template>
      <template slot="buttons" slot-scope="props">
        <vue-ads-page-button v-for="(button, key) in props.buttons" :key="key" :class="{ buttonPageActive: button.active }" class="buttonsPage" v-bind="button" @page-change="pageChange(button.page)" />
      </template>
    </vue-ads-pagination>
  </div>
</div>
</template>

<script>
import {
  HalfCircleSpinner
} from "epic-spinners";
import VueAdsPagination, {
  VueAdsPageButton
} from "vue-ads-pagination";
import {
  mapGetters,
  mapActions,
  mapMutations
} from "vuex";
import relatoriosVue from "@/components/despachante/relatorios/relatorios.vue";
import containerAcom from "@/components/despachante/acompanharDespachante.vue";

export default {
  components: {
    HalfCircleSpinner,
    relatoriosVue,
    VueAdsPagination,
    VueAdsPageButton,
    containerAcom
  },
  data() {
    return {
      loading: false,
      page: 0,
      numberPages: 0,
      sizeWindow: 0,
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
    if (this.$route.params.id == "relatorios") return;
    this.getTagRequest();
  },
  computed: {
    ...mapGetters([
      "getNumberOfPages",
      "getUrlFilter",
      "getAccompanyServiceDespachante"
    ])
  },
  methods: {
    ...mapMutations([
      "setTagsAll",
      "setPage",
      "setNumberOfPages",
      "setUrlFilter"
    ]),
    ...mapActions(["getTags"]),

    pageChange(page) {
      this.page = page;
      this.setPage(page + 1);
      return;
    },

    async getTagRequest() {
      try {
        const result = await this.getTags();
        const tags = result.data.result;
        this.setTagsAll(tags);
      } catch (error) {
        console.log(error);
      }
    }
  },
  watch: {
    "$route.params.id": function (id) {
      if (this.$route.params.id == "relatorios") return;
      this.getTagRequest();
    },
    numberPages() {
      this.setNumberOfPages(this.numberPages);
    },
    getNumberOfPages(value) {
      this.numberPages = value;
    }
  }
};
</script>

<style scoped>
* {
  list-style: none;
  padding: 0;
}

li {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #98c4e6;
}

header {
  border-bottom: 2px solid #3f88c1;
}

.bg {
  background-color: red;
}

button {
  color: #98c4e6 !important;
  height: 17px;
  width: 27px;
  border: none !important;
  background-color: none !important;
}

.buttonsPage {
  color: #98c4e6 !important;
  height: 20px;
  margin: 10px;
  width: 20px;
  border: none !important;
  background: none;
  outline: none;
}

.buttonPageActive {
  border-radius: 3px;
  color: #2474b2;
  background: #fff;
}

@media screen and (max-width:767px) {
  .welcomeMobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #217ABF;
    height: 40px;
    margin-bottom: 3%;
  }

  .imgWelCont {
    width: 44%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .txtWelCont {
    width: 77%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .txtWelCont p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .imgWelCont img {
    padding-left: 7%;
    width: 28%;
  }

  header {
    margin-top: 0 !important;
    border: none;
  }

  .allContRel {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>

<template>
  <div class="client">
    <div>
      <h3>Dados:</h3>
      <!-- <button>Editar</button> -->
    </div>
    <ul :class="openCollapse ? 'removeHeight' : ''">
      <li v-for="item in dataTransaction.dataTransaction" :key="item">
        {{ item }}
      </li>
    </ul>
    <div
      class="d-flex justify-content-center"
      v-if="
        dataTransaction.dataTransaction &&
          dataTransaction.dataTransaction.length > 4
      "
    >
      <button
        class="toggle"
        data-toggle="collapse"
        data-target="#demo"
        @click="collapseOpen"
      >
        {{ openCollapse ? "Ver menos" : "Ver mais" }}
        <img
          :class="openCollapse ? 'rotate' : ''"
          src="@/assets/image/icones/toggle.png"
          alt="toggle"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataTransaction: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      openCollapse: false
    };
  },
  methods: {
    collapseOpen() {
      this.openCollapse = !this.openCollapse;
    }
  }
};
</script>

<style scoped>
.client h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4c4d5b;
}

ul {
  list-style: none;
  padding: 0;
  max-height: 100px;
  overflow: auto;
  transition: all 5s ease-in-out;
}

.removeHeight {
  max-height: initial;
}

ul li {
  font-weight: 400;
  font-size: 14px;
  color: #797984;
  margin-bottom: 10px;
}

.toggle {
  font-weight: 600;
  font-size: 16px;
  line-height: 113.2%;
  color: #98c4e6;
  outline: none;
  transition: 0.1s ease-in-out;
  border: none;
  background: none;
}

.toggle:hover {
  background: #f9f9f9;
}

.toggle img {
  transform: rotate(175deg);
  transition: 0.1s ease-in-out;
}

.rotate {
  transform: rotate(0deg) !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"containerHeader"},[_c('h2',[_vm._v(" Documentos "+_vm._s(_vm.document.documents ? ("Enviados: " + (_vm.document.documents.filter(function (s) { return s.id !== null; }).length) + "/" + (_vm.document.documents.length)) : ":")+" ")])]),(_vm.document.documents.length <= 0)?_c('div',[_c('h2',{staticClass:"text-center not"},[_vm._v("Nenhum Documento anexado")])]):_c('div',{staticClass:"w-50"},_vm._l((_vm.document.documents),function(item,index){return _c('div',{key:item.name,class:item.id
          ? 'd-flex align-items-center'
          : 'd-flex align-items-center justify-content-between',on:{"click":function($event){return _vm.update(item)}}},[(_vm.get_tenant.isDispatcher && item.id)?_c('div',[_c('label',{staticClass:"containerCheck"},[_c('input',{attrs:{"type":"checkbox","disabled":!_vm.get_tenant.isDispatcher || _vm.paramsRouter != 'solicitacao'},domProps:{"checked":item.approve},on:{"click":function($event){return _vm.update(item)}}}),_c('span',{staticClass:"checkmark"})])]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-center boxItem cursorP"},[(item.id && item.approve)?_c('font-awesome-icon',{staticClass:"text-success mr-2",attrs:{"icon":"fas-regular fa-check"}}):_c('font-awesome-icon',{staticClass:"text-danger mr-2",attrs:{"icon":"fas-solid fa-xmark"}}),_c('img',{attrs:{"src":require("@/assets/image/iconesUpload/2.svg"),"alt":"pdf"}}),_c('p',[_vm._v(" "+_vm._s(item.documentName.substring(item.documentName.indexOf("_") + 1))+" ")])],1),(
          !item.approve && !_vm.get_tenant.isDispatcher && _vm.document.tagStatus == 2
        )?_c('div',[(
            !item.operated &&
              item.documentName == item.documentNameDefault &&
              (!_vm.fileSend[index] || _vm.fileSend[index].name == '')
          )?_c('button',{staticClass:"btnIcons defaultUpload",on:{"click":function($event){return _vm.openFile(item.sequence)}}},[_c('font-awesome-icon',{attrs:{"icon":"fas-solid fa-arrow-up-from-bracket"}})],1):_vm._e(),(
            item.operated && item.documentName !== item.documentNameDefault
          )?_c('button',{staticClass:"btnIcons bg-danger",on:{"click":function($event){return _vm.deleteItemDoc(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"fas-regular fa-trash"}})],1):_vm._e(),(item.documentName == item.documentNameDefault)?_c('div',[(_vm.fileSend[index] && _vm.fileSend[index].name != '')?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex mx-2 align-items-center justify-content-center"},[_c('font-awesome-icon',{staticClass:"colorIcon",attrs:{"icon":"fas-regular fa-circle-info","data-toggle":"tooltip","data-placement":"right","title":_vm.fileSend[index].name}})],1),(_vm.fileSend[index] && _vm.fileSend[index].name != '')?_c('button',{staticClass:"btnIcons bg-danger",on:{"click":function($event){return _vm.closeItem(item.sequence)}}},[_c('font-awesome-icon',{attrs:{"icon":"fas-regular fa-trash"}})],1):_vm._e()]):_vm._e()]):_vm._e(),_c('div',[(_vm.loadFile)?_c('half-circle-spinner',{staticClass:"mx-2",attrs:{"animation-duration":1000,"size":40,"color":"red"}}):_vm._e()],1)]):_vm._e(),_c('input',{ref:"files",refInFor:true,staticClass:"none",attrs:{"type":"file","name":"file","accept":"application/pdf","id":item.sequence},on:{"change":function($event){return _vm.filesItem($event, item.sequence)}}})])}),0),(_vm.paramsRouter == 'solicitacao')?_c('button',{staticClass:"btnFile send",on:{"click":function($event){return _vm.dowloadFiles(_vm.document)}}},[_vm._v(" Baixar Arquivos ")]):_vm._e(),(_vm.allFiles.length)?_c('button',{staticClass:"btnFile send mx-2",on:{"click":_vm.ServiceDispatcherCreate}},[_vm._v(" Upload ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btns"},[_c('vue-json-to-csv',{attrs:{"json-data":_vm.dataJson,"labels":{
      orderId: { title: 'PEDIDO' },
      tenantName: { title: 'UNIDADE CLIENTE' },
      tenantDispatcher: { title: 'UNIDADE DESPACHANTE' },
      vehiclePlate: { title: 'PLACA' },
      serviceName: { title: 'SERVIÇO' },
      status: { title: 'STATUS' },
      tag: { title: 'TAG' },
      dateOperation: { title: 'DATA - HORA' },
      value: { title: 'PREÇO' },
      comissionTenant: { title: 'RECEITA UNIDADE CLIENTE' },
      comissionDispatcher: { title: 'COMISSÃO UNIDADE DESPACHANTE' }
    },"separator":';'},on:{"success":function (val) { return _vm.handleSuccess(val); },"error":function (val) { return _vm.handleError(val); }}},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],ref:"exports",staticClass:"btn_default"},[_vm._v(" Extrair ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }